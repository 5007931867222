a:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

.has-success::after,
.has-danger::after {
  top: 35px;
}

.mw-100 {
  max-width: 100px;
}

.mw-120 {
  max-width: 120px;
}

.mw-150 {
  max-width: 150px;
}

.mw-180 {
  max-width: 180px;
}

.mw-200 {
  max-width: 200px;
}

.mw-220 {
  max-width: 220px;
}

.mw-250 {
  max-width: 250px;
}

.mw-280 {
  max-width: 280px;
}

.mw-300 {
  max-width: 300px;
}

.mw-320 {
  max-width: 320px;
}

.mw-350 {
  max-width: 350px;
}

.mw-400 {
  max-width: 400px;
}

/* User list/ dish list/ dropdown-search-button */
.control-header {
  a.btn-success {
    margin: 5px 0 0 0;
  }

  @media (max-width: 575px) {}

}



/* FORM */
.form-control {
  &.is-invalid {
    color: #f00;
    border-color: #f00;
  }
}

label.is-invalid {
  color: #f00 !important;
}


.main-panel {
  .navbar-fixed {

    .flag-select {

      .flag-select__btn {
        cursor: pointer;
      }

      .flag-select__option__icon {
        top: 3px;
      }

      .flag-select__btn::after {
        border-top: 4px solid #000;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}

.nav-tabs-custom {
  .nav-link {
    border-top: none;
    border-left: none;
    border-right: none;

    &.active {
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
    }
  }
}


/*@media (min-width:992px){*/
@media (min-width: 1200px) {
  .wrapper {
    &:before {
      background: #00f2c3;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      height: 2px;
      content: "";
      z-index: 100000;
    }
  }

  .sidebar {
    height: 100vh;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0;
    width: 250px;

    .nav {
      li>a {
        font-size: 0.8125rem;

        p {
          font-weight: 600;
        }
      }
    }

    .logo {
      text-align: center;
    }

    .MeDietetic-logo-full {
      height: 45px;
    }

    .MeDietetic-logo-full-st0,
    .MeDietetic-logo-full-st1,
    .MeDietetic-logo-full-st2 {
      fill: #fff;
    }

    /*
    .MeDietetic-logo-full-st0{fill:#8dbf8e}
    .MeDietetic-logo-full-st1{fill:#bb87bb}
    .MeDietetic-logo-full-st2{fill:#73aac6}*/
  }

  .main-panel {
    .navbar-fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 270px;
      width: auto;
      background: #f5f6fa !important;
      padding: 6px 10px 5px 10px;

      &:after {
        display: block;
        height: 25px;
        position: fixed;
        top: 62px;
        left: 270px;
        right: 0;
        background: rgb(245, 246, 250);
        background: -moz-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
        background: linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f6fa", endColorstr="#f5f6fa", GradientType=1);
        content: "";
      }

      .navbar-brand {
        margin: 0;
        padding: 0;
        font-size: 1.625rem;
        text-transform: none;
        font-weight: 400;
        min-height: 39px;
      }

      .ml-auto {
        text-align: right;
        width: 200px;
      }

      .navbar-nav {
        float: right;
        clear: left;
      }

      .flag-select {
        top: 6px;

        .flag-select__options {
          overflow: visible;
          padding: 5px 15px;
          border: none;
          box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
          border-radius: 0.1428rem;
          transition: all 150ms linear;
          right: 0;

          &:before {
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            vertical-align: middle;
            content: "";
            top: -5px;
            right: 15px;
            left: auto;
            color: #fff;
            border-bottom: .4em solid;
            border-right: .4em solid transparent;
            border-left: .4em solid transparent;
            z-index: 100000000;
          }

          .flag-select__option {
            margin: 0;
            list-style: none;
            padding-top: .6rem;
            padding-bottom: .6rem;

            &:hover {
              background: none;
            }

            img {
              border-radius: 0;
              float: left;
              clear: right;
              margin-top: -2px;

            }

            .flag-select__option__label {
              display: inline-block;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }

  .main-panel[data="green"] {
    border-top: none;
  }

  .main-panel>.content {
    padding: 90px 30px 30px 280px;
  }
}


/*@media (max-width:991px){*/
@media (max-width: 1199px) {
  .wrapper {
    &:before {
      background: #00f2c3;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      height: 2px;
      content: "";
      z-index: 100000;
    }
  }

  .sidebar {
    .nav {
      li>a {
        font-size: 0.8125rem;

        p {
          font-weight: 600;
        }
      }
    }

    .logo {
      text-align: center;
    }

    .MeDietetic-logo-full {
      height: 45px;
    }

    .MeDietetic-logo-full-st0,
    .MeDietetic-logo-full-st1,
    .MeDietetic-logo-full-st2 {
      fill: #fff;
    }

    /*
    .MeDietetic-logo-full-st0{fill:#8dbf8e}
    .MeDietetic-logo-full-st1{fill:#bb87bb}
    .MeDietetic-logo-full-st2{fill:#73aac6}*/

  }

  .main-panel {
    .navbar-fixed {
      position: fixed;
      top: 2px;
      left: 20px;
      right: 0;
      width: auto;
      background: #f5f6fa !important;
      padding: 14px 0 13px 0;


      &.navbar-transparent {
        &:after {
          display: block;
          height: 25px;
          position: fixed;
          top: 62px;
          left: 20px;
          right: 0;
          background: rgb(245, 246, 250);
          background: -moz-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          background: -webkit-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          background: linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f6fa", endColorstr="#f5f6fa", GradientType=1);
          content: "";
          transition: all 0.2s ease-in-out;
        }
      }

      &.bg-white {
        &:after {
          display: none;
          height: 25px;
          position: fixed;
          top: 155px;
          left: 20px;
          right: 0;
          background: rgb(245, 246, 250);
          background: -moz-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          background: -webkit-linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          background: linear-gradient(180deg, rgba(245, 246, 250, 1) 0%, rgba(245, 246, 250, 0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f6fa", endColorstr="#f5f6fa", GradientType=1);
          content: "";
          transition: all 0.3s ease-in-out;
        }
      }

      .container-fluid {
        padding-left: 0;
      }

      .navbar-brand {
        margin: 0 0 0 20px;
        padding: 0;
        font-size: 1.375rem;
        text-transform: none;
        font-weight: 400;
        min-height: 33px;
      }

      .navbar-nav {
        .dropdown-toggle {
          .caret {
            margin-left: 40px;
            top: 27px;
          }
        }
      }

      .flag-select {
        display: block;
        padding-bottom: 0;

        .flag-select__options {
          position: relative;
          border: none;
          border-radius: 0;
          background: none;
          margin: 0;
          padding: 10px 0 0 8px;
          max-height: initial;
          width: 100%;
          display: block;

          .flag-select__option {
            display: inline-block;
            padding: 0;
            margin: 0 8px 10px 8px;

            span {
              width: initial;
              height: initial;

              .flag-select__option__icon {
                top: -1px;
                width: 1.3em;
                height: 1.3em;
              }

              .flag-select__option__label {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .main-panel[data="green"] {
    border-top: none;
  }

  .main-panel>.content {
    padding-top: 90px;
  }
}


@media (max-width: 767px) {
  .main-panel {
    .navbar-fixed {
      left: 15px;
      right: 15px;

      &.navbar-transparent {
        &:after {
          left: 15px;
          right: 15px;
        }
      }

      &.bg-white {
        &:after {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}


/* DASHBOARD */
.approval {
  .nav-tabs {
    margin-top: 15px;
  }

  .card {
    margin-bottom: 0;
  }

  .tab-pane {
    padding-top: 15px;
  }
}

.icon-wrapper {
  margin: 5px 20px 20px 7px;
  background: -moz-linear-gradient(top, #00f2c3 0%, #0098f0 100%);
  background: -webkit-linear-gradient(top, #00f2c3 0%, #0098f0 100%);
  background: linear-gradient(to bottom, #00f2c3 0%, #0098f0 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-card-footer {
  margin: 7px 7px 7px 7px;
  text-align: left;
}

@media (min-width: 1200px) {
  .content-dashboard {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }
}

/* DASHBOARD */


.content-calendar {

  /* Action */
  .chart-container {
    max-width: 450px;
  }


  td .badge {
    width: calc(100% - 30px);
  }

  .btn.btn-icon.btn-xs {
    height: 22px;
    min-width: 22px;
    width: 22px;
    margin-left: 5px;
    top: 1px;
    z-index: 50;
  }


  .btn.btn-icon.btn-xs .fas {
    font-size: 0.75rem !important;
  }

  @media (min-width: 991px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }

  .main-td1 {
    position: relative;
  }


  .main-td {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0%;
    left: 0%;
    z-index: 40;
  }
}


/* CLIENTS */
.content-client {

  @media (min-width: 991px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }

  .gJIsUq {
    min-width: 200px !important;
  }

  /* Email */
  .fxrvRh {
    min-width: 80px !important;
  }

  /* Active */
  .gmDRvu {
    min-width: 110px !important;
  }

  /* Joined */
  .gxxvtN {
    min-width: 105px !important;
  }

  /* Action */
  .hkVXPp {
    min-width: 100px !important;
  }

  /* Issue */

  .meta-table {
    .table-responsive {
      overflow: auto;
    }

    input {
      min-width: 85px;
    }

    /*.action-button{
      display: inline-block;
      min-width: 60px;
    }*/
  }

  .meal-item.meal,
  .meal-item.meal:hover {
    background: #267cadd5 !important;
  }

  .meal-item.dish,
  .meal-item.dish:hover {
    background: #fd985dc7 !important;
  }

  .content-client .has-success::after,
  .content-client .has-danger::after {
    right: 15px;
    top: 35px;
    font-size: 12px;
    font-weight: 700;
  }

  .datepicker-wrapper label {
    display: block;
  }

}

/* CLIENTS */


/* FOOD */
.content-food {
  .fIUknV {
    min-width: 105px !important;
  }

  /* Action */
  .info-link {
    cursor: pointer;
    border-bottom: 1px dotted #000;
    transition: all 0.15s ease-in-out;
    white-space: nowrap;

    &:hover {
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
    }
  }

  @media (min-width: 550px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }

  @media (max-width: 1439px) {
    .line-dotted {
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted #000;
      margin: 33px 0 38px 0;
    }
  }

  .form-check input[type="checkbox"]:checked+.form-check-sign::before,
  .form-check input[type="checkbox"]:checked+.form-check-sign::before {
    border: none;
    background-color: seagreen;
  }
}

/* FOOD */


/* DISH */
.content-dish {
  .gxxvtN {
    min-width: 105px !important;
  }

  /* Action */
  .chart-container {
    max-width: 450px;
  }

  .card-micronutrients {
    overflow: hidden;
  }

  .card-minerals {
    overflow: hidden;
  }

  .form-check input[type="checkbox"]:checked+.form-check-sign::before,
  .form-check input[type="checkbox"]:checked+.form-check-sign::before {
    border: none;
    background-color: seagreen;
  }

  @media (min-width: 991px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }
}

/* DISH */



/* DIET */
.content-diet {
  .fIUknV {
    min-width: 105px !important;
  }

  /* Action */
  @media (min-width: 768px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
      }
    }
  }

  .form-check input[type="checkbox"]:checked+.form-check-sign::before,
  .form-check input[type="checkbox"]:checked+.form-check-sign::before {
    border: none;
    background-color: seagreen;
  }
}

/* DIET */


/* MEAL */
.content-meal {
  .invalid-info {
    padding-top: 5px;
    display: inline-block;
  }

  @media (min-width: 768px) {
    .even-height-row {
      .even-height-card {
        height: calc(100% - 30px);
        overflow: hidden;
      }
    }
  }

  .form-check input[type="checkbox"]:checked+.form-check-sign::before,
  .form-check input[type="checkbox"]:checked+.form-check-sign::before {
    border: none;
    background-color: seagreen;
  }
}

/* MEAL */


/* DATEPICKER */
.react-datepicker {
  border: none;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.1428rem;
  font-family: "Poppins", sans-serif;

  .react-datepicker__header {
    border-bottom: 1px solid #e3e3e3;
    background: #f5f5f5;
  }

  .react-datepicker__day {
    border-radius: 50%;
  }

  .react-datepicker__day--selected {
    background: #0098f0;

    &:hover {
      background: #0191e5;
    }
  }
}

/* DATEPICKER */


/* USER LIST */



/* USER LIST */



/* LOGIN - REGISTER */
.container-login,
.container-register {
  height: 100vh;

  .row-main {
    height: 100vh;
  }

  .col-md-12 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .card {
    margin-top: 30px;
    max-width: 480px;
  }

  h2 {
    margin-top: 10px;
    text-transform: uppercase;
  }

  .btn {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

/* Login */
.container-login {
  .logo-color {
    max-width: 320px;
    margin-top: 50px;
  }

  .MeDietetic-logo-full {
    max-width: 320px;
    margin-top: 50px;
  }

  .MeDietetic-logo-full-st0 {
    fill: #8dbf8e
  }

  .MeDietetic-logo-full-st1 {
    fill: #bb87bb
  }

  .MeDietetic-logo-full-st2 {
    fill: #73aac6
  }

  form {
    text-align: center;
  }
}


/* Register */
.container-register {
  form label {
    font-size: 0.875rem;
    cursor: pointer;
  }

  .react-datepicker__triangle {
    display: none !important;
  }
}

/* LOGIN - REGISTER */


/* FIX TABLE HEADER */
.tableFixHead {
  overflow: auto;
  height: 100px;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    padding: 8px 16px;
    background: #eee;
  }

  td {
    padding: 8px 16px;
  }

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }
  }

  tbody {
    th {
      position: sticky;
      left: 0;
    }
  }
}

/* FIX TABLE HEADER */


/* MODAL FIX */
.modal-dialog .modal-header {
  cursor: move;
}

/* MODAL FIX */


/* POPUP INFO FIX */
.uTooltip {

  .tooltip {
    -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    /*top:5px;
    bottom:5px;*/

    &.bs-tooltip-auto {
      padding: 0;
    }
  }

  /* Tooltip title */
  &-title {
    .tooltip-inner {
      padding: 15px;
    }
  }

  /* Tooltip input */
  &-input {
    .tooltip-inner {
      padding: 8px 15px;
    }
  }

  /* Tooltip info */
  &-info {
    .tooltip-inner {
      padding: 10px 20px;
    }
  }

}

/* POPUP INFP FIX */



/* Horizontal scroll */
@media (min-width: 1200px) and (max-width: 1930px) {
  .nav-item {
    margin-top: 2px;
  }
}

/* Horizontal scroll */

.rdt_TableRow {
  min-height: initial !important;
}

.rdt_TableHeadRow {
  min-height: 48px !important;
}

.white-content .table>tbody>tr>td {
  padding: 8px 7px !important;
}

.badge-red {
  color: #ffffff;
  background-color: hsl(0, 97%, 58%);
}

.badge-red[href]:hover,
.badge-red[href]:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: hsl(0, 97%, 58%);
}