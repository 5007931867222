.date-picker-wrapper>.react-datepicker-popper>.react-datepicker{
    margin-top: 220px
}

.date-period span{
    font-size: 1.25rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    min-width:210px;
    display: inline-block;
}


.content-calendar .table-responsive{
    overflow: initial;
    overflow-x: auto;
}

.calendar.table{
    width:99.98%;
    margin-bottom: 0;
}


.calendar th {
    text-align: center;
    width: 300px;
    border:none;
    min-width:85px;
}

.calendar td {
    text-align: center;
    width: 300px;
    height: 100px;
    cursor: pointer;
    border:none;
    min-width:85px;
}

.calendar thead th{
    color: #525f7f!important;
}

.calendar tbody{
    border: 0.0625rem solid #e3e3e3;
}
.calendar tbody tr{
    border: 0.0625rem solid #e3e3e3;
}
.calendar tbody th{
    font-family: "Poppins", sans-serif;
    padding: 5px;
    font-weight: 600;
}
.calendar tbody td{
    font-family: "Poppins", sans-serif;
    border-left: 0.0625rem solid #e3e3e3;
    padding: 5px 5px 0 5px;
}

.calendar .meal-item {
    /*font-family: "Consolas", sans-serif;*/
    font-family: "Poppins", sans-serif;
    font-size:  	0.875rem;
    width: 100%;
    margin: 0 0 5px 0;
    font-weight: 600;
}
.calendar .meal-item.meal,
.calendar .meal-item.meal:hover{
    background: #267cadd5!important;
}
.calendar .meal-item.dish,
.calendar .meal-item.dish:hover {
    background: #fd985dc7!important;
}
.calendar .meal-item.error {
    background: #fd5d93;
}


@media (min-width:320px) and (max-width:500px){
    .date-picker-wrapper{
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center!important;
    }

    .date-period{
        flex: 0 0 100%;
        max-width: 100%;
        padding-top:10px;
    }
    .date-period span{
        font-size: 1rem;
        min-width:150px;
    }
}