  .login-input.form-control {
    background-color: transparent;
    color: #00add1 !important;
  }

  .login-input::placeholder {
    color: #00add1 !important;
  }

  .date-picker-login-input {
    height: 38px;
    border-radius: 7px;
    border: 0.5px solid gray;
    background-color: rgba(54, 25, 25, .00004);
    color: black;
    padding-left: 12px;
  }

  .date-picker-login-input.form-control {
    background-color: transparent;
    color: #00add1 !important;
  }

  .date-picker-login-input::placeholder {
    color: #00add1 !important;
  }

  .form-input-error {
    color: red;
    font-size: 13px;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .form-input-error-text {
    color: #a18d81;
    font-size: 13px;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .form-input-error-displayed {
    margin-bottom: 0;
  }
  
  .form-input-error-not-displayed {
    margin-bottom: 16;
  }
